<template>
    <b-modal class="bmodal" size="sm" button-size="sm" id="individualModal" ref="modalIndividual"
        :title="'Individual Details'" :cancel-title-html="`Cancel`" :ok-title-html="`Confirm`" centered :hide-footer="false"
        :no-close-on-backdrop="noCloseOnBackdrop">
        <div class="formdiv">
            <b-form>
                <b-row class="lh42">
                    <b-col sm="12" class="font-style-3">
                        {{ translations.tcMemberNumber }}
                        {{ member_number }}
                    </b-col>
                </b-row>
                <b-row class="lh42">
                    <b-col sm="12" class="font-style-3">
                        Name
                        {{ name }}
                    </b-col>
                </b-row>
                <b-row class="lh42">
                    <b-col sm="12" class="font-style-3">
                        Email
                        {{ email }}
                    </b-col>
                </b-row>
                <b-row class="lh42">
                    <b-col sm="12" class="font-style-3">
                        Phone
                        {{ phone }}
                    </b-col>
                </b-row>
                <b-row class="lh42">
                    <b-col sm="12" class="font-style-3">
                        Address
                        {{ address }}
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
    name: 'individual-information',
    mixins: [translationMixin],
    props: {
        modal_title: {
            type: String,
            default: null,
        },
        member_number: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        phone: {
            type: String,
            default: null,
        },
        address: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            camp_country_key: null,
            country_us: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
            findReturn: null,
            member_only_search: true,
            noCloseOnBackdrop: true,
            other_country: false,
            postal_code: null,
            selected_country: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
            selected_state: null,
            translations: {},
        }
    },
    computed: {
        ...mapGetters({
            addressFormOptions: 'membership/addressFormOptions',
            countryStates: 'search/countryStateSelect',
            matchingIndividuals: 'search/matchingIndividuals',
            prefCulture: 'user/userPreferredCulture',
            searchCountryKey: 'search/countryKey',
        }),
        showNoResults() {
            return this.matchingIndividuals.length === 0 && this.findReturn
        },
        isUSSelected() {
            return this.camp_country_key === this.country_us
        },
        countriesList() {
            return this.addressFormOptions.countries.map((ct) => {
                return { text: ct.display_text, value: ct.value }
            })
        },
    },
    methods: {
        ...mapActions({
            clearSearches: 'search/clearSearches',
            getCountryForCamp: 'search/getCountryForCamp',
            getCountryStates: 'search/getStatesForCountry',
            loadAddressFormOptions: 'membership/loadAddressFormOptions',
            locateIndividuals: 'search/locateIndividuals',
            setLoadingStatus: 'menu/setLoadingStatus',
        }),
        async modalBack() {
            this.findReturn = null
            this.clearSearches()
            await Promise.all([this.getCountryStates(this.camp_country_key)])
        },
        closeModal() {
            this.clearSearches()
            this.$refs.modalPersonSearch.hide()
        },
        handleCancelClick() {
            this.selected_state = null
            this.postal_code = null
            this.member_number = null
            this.last_name = null
            this.first_name = null
            this.findReturn = null
        },

        async handleOkClick(e) {
            e.preventDefault()
            this.findReturn = null
        },
        async setCountryKey() {
            if (!!this.camp_country) {
                this.camp_country_key = this.camp_country
            } else if (!!this.cmp_org_key) {
                await this.getCountryForCamp(this.cmp_org_key)
                this.camp_country_key = this.searchCountryKey
            } else {
                // If neither country key or camp key is passed in, default to US.
                this.camp_country_key = this.country_us
            }
        },
    },

    async created() {
        this.translations = (await this.getComponentTranslations('individual-information'))['individual-information']
    },
}
</script>
  
<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.noresults {
    color: #dd0000;
    font-weight: 600;
}

.resultDiv {
    cursor: pointer;
}

.resultDiv:hover {
    background-color: gainsboro;
}

.lh42 {
    margin-bottom: 6px;
}

.long_list {
    margin: 20px 8px;
    max-height: 398px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.long_list ul li {
    font-size: 13px;
    margin-bottom: 10px;
}

.font-style-3 {
    font-weight: 600;
    text-transform: uppercase;
}
</style>
  