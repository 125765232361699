/**
 * Formats a US phone number to (###)-###-#### *
 * @param {string} phoneNumber
 * @returns {string}
 */
export default function FormatUSPhoneNumber (phoneNumber) {
  if (!phoneNumber) return
  if (phoneNumber.length <= 3) return phoneNumber
  const middleThreeDigits = phoneNumber.substring(3, 6)
  const lastFourDigits = phoneNumber.substring(6, 10)
  return `(${phoneNumber.substring(0, 3)})${middleThreeDigits ? ' ' + middleThreeDigits : ''}${lastFourDigits ? '-' + lastFourDigits : ''}`
}
