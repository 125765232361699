<template lang="">
 <input type="text" class="form-control form-control-sm input-mask"
  :value="formattedInputValue" @input="onInputChange($event)"  :placeholder="placeholder" />

</template>
<script>
import FormatUSPhoneNumber from '@/services/utils/FormatUSPhoneNumber.js'
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maximumDigitsAllowed: {
      type: Number,
      default: 10
    },
    customFormatter: {
      type: Function,
      default: FormatUSPhoneNumber
    }
  },

  computed: {
    formattedInputValue () {
      return this.value && this.customFormatter(this.value.toString())
    }
  },
  methods: {
    onInputChange (event) {
      const inputValue = event.target.value
      const matchPattern = /\D/g
      const currentValue = inputValue.replaceAll(matchPattern, '')
      if (currentValue === '') {
        this.$emit('input', '')
        event.target.value = ''
        return
      }
      if (currentValue.toString().length <= this.maximumDigitsAllowed) {
        this.$emit('input', parseInt(currentValue))
        event.target.value = this.customFormatter(currentValue)
      } else {
        event.target.value = this.customFormatter(this.value.toString())
        this.$emit('input', this.value)
      }
    }

  }
}
</script>
